import React from 'react';
import Testimonial from '../../components/Testimonial';
import FAQAccord from '../../components/GFlush';
import Layout from '../../components/Layout';
import { Seo } from '../../components/seo';
import Counter from '../../components/Counter';
import Guidance from '../../components/GSTTemp/Guidance';
// import Hero from "../../components/Hero";
import AboutWithForm from '../../components/GSTTemp/AboutWithForm';
import ScrollNav from '../../components/GSTTemp/ScrollNav';
import ImgContent from '../../components/GSTTemp/ImgContent';
import ContentForm from '../../components/GSTTemp/ContentForm';
import Cta from '../../components/Cta';
import ContentImg from '../../components/GSTTemp/ContentImg';
import Package from '../../components/GSTTemp/Package';

export default function LAdvisory() {
  /* Slider */
  // const LegalAdvSlider = [
  //   {
  //     header_color: true,
  //     content_color: true,
  //     background: `linear-gradient(139.4deg, #0D324D, #7F5A83)`,
  //     header: `Legal Advisory Services`,
  //     content: `Online legal advisory services form the professionals `,
  //     image: "/imgs/registration/tax/tax-slider.png",
  //     alt_tag: "Best Legal Advisory Services  in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Legal Advisory Service`,
    buyBtnLink: `#pricing-buy`,
    price: `2499`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const LegalAdvAboutData = {
    header: `Legal Advisory Services`,
    sub_title: `For Personal and Business related legal advisory services`,
    content_paragraph: [
      `TODAYFILINGS is well-versed in both legal requirements and industry
        standards. Our legal consultation services assist our
        customer in maintaining compliance with the law in the
        current market environment. The Indian legal sector is seeing an increase in demand for
        legal advisory services. These services' outsourcing is
        unquestionably one of the profession of law's emerging
        trends. It reduces the firms' workload and may result in
        cost savings.`,
      // `Legal consulting services assist businesses with legal issues
      // relating to their client operations, industry presence, size,
      // and location. During mergers, takeovers, IPOS, etc.,`,
    ],
  };

  /* Scroll Nav Data */
  const LegalAdvScrollId = [
    {
      id: `#skills`,
      heading: `Advisor Skills`,
    },
    {
      id: `#advisor`,
      heading: `Advisor`,
    },

    {
      id: `#legal-adv-benefits`,
      heading: `Benefits`,
    },
  ];

  /* Img Content Component Data */
  const LegalAdvIcData = {
    id: 'skills',
    mt_div: 'mt-5',
    mt_img: 'mt-1',
    header: 'Legal Advisor Skills',
    image: '/imgs/registration/tax/tax-reg.png',
    alt_tag: 'Legal Advisor Skills',
    Paragraph: `An adept in managing organizational and
      problem-solving skills, lawyers are critical thinkers.
      They are excellent at making goals, negotiating, and
      meeting deadlines. A legal advisor needs to be able to
      work independently. Additionally, he must demonstrate
      leadership qualities and be a team player.A legal advisor 
      also exhibits the following abilities:`,
    points: [
      {
        head: 'Basic Skills',
        content: `Examining and drafting agreements, contracts, and
          other legal documents.`,
        icon: true,
      },
      {
        content: `Bargaining for internal and external contracts.`,
        icon: true,
      },
      {
        content: `Reviewing company guidelines and procedures for
          compliance with legal requirements.`,
        icon: true,
      },
      {
        content: `Making use of employment legal knowledge to settle
          disputes and assure compliance.`,
        icon: true,
      },
      {
        content: `Grants, Both Now and Later Financial Diagram with
          Analysis of Working Capital Needs Offer the most
          advantageous cash securities.`,
        icon: true,
      },
      {
        head: 'Professional Level Skills',
        content: `Comprehensive knowledge of financial concepts and
          procedures.`,
        icon: true,
      },
      {
        content: `Proficiency with Microsoft Word, Excel, and Adobe
          Acrobat.`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const LegalAdvCfData = {
    id: 'advisor',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Legal Advisor',
    content: ``,
    body: [
      {
        head: 'Who is a Legal Advisor?',
        points: `Legal advisor is a lawyer who is an expert in a
          specific field of law and gives legal advice to
          businesses. Contract conflicts, pay concerns,
          workplace difficulties, and other matters can be
          handled by them. They also provide expert advice on
          business decisions, mergers and acquisitions,
          corporate expansion, and other operations.`,
        icon: true,
      },
      {
        head: 'Duties and Responsibilities of a Legal Advisor',
        points: '',
        icon: false,
      },
      {
        head: 'Contract Creation and Negotiation',
        points: `Legal advisors are familiar with establishing
          agreements such as consultant contracts, collaboration
          agreements, and other sorts of contracts. A legal
          advisor's role also includes reviewing current
          contracts and preparing new papers.`,
        icon: true,
      },
      {
        head: 'Ensure Corporate Law Compliance',
        points: `A legal advisor is a corporate law expert who reviews
          all company activities and works hard to ensure that
          all federal and state legal requirements are followed.
          They are well-versed in all legal standards and rules
          relevant to their industry`,
        icon: true,
      },
      {
        head: 'Provide Employee And Management Conflict Resolution Counseling',
        points: `Legal representative tackles sexual harassment, labour
          disputes, and other employee difficulties. In
          resolving these challenges, the counsellor employs his
          relevant knowledge.`,
        icon: true,
      },
    ],
  };

  /*  Content Img Component Data */
  const LegalAdvCiData = {
    id: 'legal-adv-benefits',
    background: '',
    mt_div: '',
    mt_img: 'mt-5',
    header: 'Legal advisory services advantages',
    paragraph: `Five Important Advantages Of Contracting Out Legal
    Advisory Services:`,
    points: [
      {
        content: `Cost-Effective.`,
        icon: true,
      },

      {
        content: `Greater adaptability.`,
        icon: true,
      },

      {
        content: `Round-the-clock-service.`,
        icon: true,
      },
      {
        content: `Access to specialists with training.`,
        icon: true,
      },
      {
        content: `Access to cutting-edge technologies.`,
        icon: true,
      },
      {
        content: `Form a specific team with the core team members
        identified to evaluate the market and financial
        situation and develop policies.`,
        icon: true,
      },
    ],
    image: '/imgs/assited/tax-audit-objective.png',
    alt_tag: 'Benefits of Legal Advisory Services',
  };
  var FAQ_data = [
    {
      header: 'How long does it take to meet with a lawyer?',
      body: [
        {
          content: `Although we arrange each initial consultation for one hour, your
          session may take longer or shorter. It is determined by the
          complexity of your circumstance and the amount of information you
          were able to gather prior to your initial contact with an
          attorney.`,
          icon: false,
        },
      ],
    },
    {
      header: ' How legal advisory services prove cost-effective? ',
      body: [
        {
          content: `Outsourcing legal service proves cost-effectively beneficial to the organization. It can lead 
          to huge cost saving, since hiring qualified personnel and investing money and time in training them can 
          turn out to be quite costly. But when you choose to outsource these services you need not spend a single 
          penny on the employee’s training.`,
          icon: false,
        },
      ],
    },
    {
      header: ' What should someone consider before becoming a legal advisor? ',
      body: [
        {
          content: `The job may not be for the faint of heart. You might be the most hated person in the company, 
          because you’ll have to tell people that they can’t do something, or tell them things that they don’t want 
          to hear.`,
          icon: false,
        },
      ],
    },
    {
      header: ' How long does a consultation with a lawyer take? ',
      body: [
        {
          content: `Although we schedule each initial consultation for one hour, your actual appointment may last 
          more or less than one hour. It depends on the complexity of your situation and how much information you 
          were able to have organized before your initial consultation with an attorney.`,
          icon: false,
        },
      ],
    },

    {
      header:
        'What should a person think about before becoming a legal advisor?',
      body: [
        {
          content: `This is not a job for the faint of heart. Because you'll have to
          tell people they can't do something or tell them something they
          don't want to hear, you can be the most despised person in the
          company.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What difficulties does a Legal Advisor face?',
      body: [
        {
          content: `Being a Legal Advisor is fraught with difficulties. Every area of
          law is vast and difficult, but you will be expected to be a
          generalist and specialist in the majority of them. Another
          difficulty is dealing with the stress of making decisions that
          could have major consequences for the business.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What are the most important abilities for a legal advisor?',
      body: [
        {
          content: `Negotiation and communication are two crucial soft skills that
          legal advisers must use on a regular basis. You must know how to
          be tough but fair in a negotiation. You must be well prepared and
          ready to deal with unexpected counter-proposals. Being able to
          communicate involves being able to effectively express legal risks
          and repercussions, as well as knowing what the business wants and
          accurately representing that in a contract.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What qualities distinguish a successful lawyer?',
      body: [
        {
          content: `Great lawyers often have a passion for a specific legal specialty
          or practice area, as well as mastery as an expert, which generates
          a high level of commitment to their profession.`,
          icon: false,
        },
      ],
    },
    {
      header: ' What makes a successful lawyer? ',
      body: [
        {
          content: `Great lawyers typically have a passion for a particular legal speciality or practice area, 
          and master excellence as an expert, which fosters a high level of commitment to their work.`,
          icon: false,
        },
      ],
    },
    {
      header: ' What are the core skills of legal advisor? ',
      body: [
        {
          content: `Reviewing and creating contracts, agreements and other legal documents`,
          icon: true,
        },
        {
          content: `Negotiating in-house and outside contracts`,
          icon: true,
        },
        {
          content: `Analyzing corporate policies and practices for adherence to laws and regulations`,
          icon: true,
        },
        {
          content: `Applying knowledge of employment laws to resolve conflicts and ensure compliance`,
          icon: true,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Legal Advisory Services'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={LegalAdvSlider} /> */}

          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={LegalAdvAboutData} />

          <Counter />

          <ScrollNav scroll_data={LegalAdvScrollId} />

          <ImgContent item={LegalAdvIcData} />

          <ContentForm CFSection_data={LegalAdvCfData} />

          <ContentImg CISection_data={LegalAdvCiData} />

          <Cta />
          <Guidance />

          <Testimonial />
          <FAQAccord items={FAQ_data} />
        </main>
      </Layout>
    </div>
  );
}
